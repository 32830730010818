export const DEPARTMENTS = {
    MEDICAL:{
        id: 13
    },
    PHARMACY:{
        id: 11
    },
    ADMIN:{
        id: 1
    },
    NURSE:{
        id: 10
    },
    RECEPTION:{
        id: 15
    },
    MEDICAL_COORDINATION:{
        id: 6
    },
    CASH_REGISTER:{
        id: 19
    },
}

export const ROLES = {
    MEDICAL: [
        DEPARTMENTS.MEDICAL.id,
    ],
    ADMIN: [
        DEPARTMENTS.ADMIN.id,
    ],
    PHARMACY: [
        DEPARTMENTS.PHARMACY.id,
    ],
    NURSE: [
        DEPARTMENTS.NURSE.id,
    ],
    RECEPTION: [
        DEPARTMENTS.RECEPTION.id,
    ],
    CASH_REGISTER: [
        DEPARTMENTS.CASH_REGISTER.id,
    ],
    MEDICAL_COORDINATION: [
        DEPARTMENTS.MEDICAL_COORDINATION.id,
    ],
}
