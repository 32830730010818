<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo" v-if="skin === 'light'">
            <b-img
              :src="appLogoImagewhite"
              alt="logo"
            />
          </span>
          <span class="brand-logo" v-else>
            <b-img
                :src="appLogoImage"
                alt="logo"
            />
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import useAppConfig from "@core/app-config/useAppConfig";
import {computed} from "@vue/composition-api";

export default {
  components: {
    BLink,
    BImg,
  },
  data(){
    return{
      state_theme:''
    }
  },

  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImagewhite } = $themeConfig.app
    const { skin } = useAppConfig()
    return {
      appName,
      appLogoImage,
      appLogoImagewhite,
      skin,
    }

  },
}
</script>

<style>
.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-logo img {
  max-width: 100px !important;
}
</style>
